import React from 'react';

const Layout = () => {
  return (
    <>
      <div className="py-16" />
    </>
  );
};

export default Layout;
